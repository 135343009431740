export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	userManagement: {
		id: 'userManagement',
		text: 'User Management',
		path: 'user-management',
		icon: 'Group',
	},
	category: {
		id: 'category',
		text: 'Category Management',
		path: 'category',
		icon: 'Category',
	},
	MassageCenters: {
		id: 'MassageCenters',
		text: 'Massage Centers',
		path: 'massage-centers',
		icon: 'spa',
		subMenu: {
			AllMassageCenters: {
				id: 'AllMassageCenters',
				text: 'All Massage Centers',
				path: 'all-massage-centers',
				icon: 'Badge',
			},
			TopRatedMassageCenters: {
				id: 'TopRatedMassageCenters',
				text: 'Top Rated MassageCenters',
				path: 'top-rated-massage-centers',
				icon: 'stars',
			},
			TopRatedMassageCentersDetail: {
				id: 'TopRatedMassageCenters',
				text: 'Top Rated MassageCenters',
				path: 'top-rated-massage-centers-details',
				hide: true,
			},
			HabitKeyManagement: {
				id: 'HabitKeyManagement',
				text: 'Habit Key Management',
				path: 'Habit-key-management',
				icon: 'offline_bolt',
			},
			HabitKeyRequestDetail: {
				id: 'HabitKeyRequestDetail',
				text: 'Habit Key Request Detail',
				path: 'Habit-key-request-detail',
				hide: true,
			},
			OrderDetail: {
				id: 'orderDetail',
				text: 'Order Detail',
				path: 'order-detail',
				hide: true,
			},
			InvoiceDetail: {
				id: 'invoiceDetail',
				text: 'Invoice Detail',
				path: 'invoice-detail',
				hide: true,
			},
		},
	},
	// HabitKeyManagement: {
	// 	id: 'HabitKeyManagement',
	// 	text: 'Habit Key Management',
	// 	path: 'Habit-key-management',
	// 	icon: 'offline_bolt',
	// 	subMenu: {
	// 		FeaturedHabitKey: {
	// 			id: 'FeaturedHabitKey',
	// 			text: 'Habit Massage Key',
	// 			path: 'featured-habit-key',
	// 			icon: 'featured_play_list',
	// 		},
	// 		HabitKeyRequestDetail: {
	// 			id: 'HabitKeyRequestDetail',
	// 			text: 'Habit Key Request Detail',
	// 			path: 'Habit-key-request-detail',
	// 			hide: true,
	// 		},
	// 	},
	// },
	featureManagement: {
		id: 'featureManagement',
		text: 'Feature Management',
		path: 'feature-management',
		icon: 'widgets',
		subMenu: {
			featuredMassageCenterManagement: {
				id: 'featuredMassageCenterManagement',
				text: 'Feature Massage Center',
				path: 'featured-center-management',
				icon: 'request_page',
			},
			featureDetail: {
				id: 'featureDetail',
				text: 'Feature Detail',
				path: 'feature-detail',
				hide: true,
			},
			featuredRequestedMassageCenterManagement: {
				id: 'featuredRequestedMassageCenterManagement',
				text: 'Feature New Request',
				path: 'requested-featured-center-management',
				icon: 'request_page',
			},
			requestedFeatureDetail: {
				id: 'requestedFeatureDetail',
				text: 'Requested Feature Detail',
				path: 'requested-feature-detail',
				hide: true,
			},
		},
	},
	configuration: {
		id: 'configuration',
		text: 'Configuration',
		path: 'configuration',
		icon: 'Settings',
		subMenu: {
			slotManagement: {
				id: 'slotsManagement',
				text: 'Feature Limit Management',
				path: 'feature-limit-management',
				icon: 'DNS',
			},
			massageConfiguration: {
				id: 'massageConfiguration',
				text: 'Massage Center Configuration',
				path: 'massage-configuration',
				icon: 'spa',
			},
			slotDetails: {
				id: 'slotDetails',
				text: 'Slot Details',
				path: 'slot-details',
				hide: true,
			},
		},
	},
	orderManagement: {
		id: 'orderManagement',
		text: 'Order Management',
		path: 'order-management',
		icon: 'Request Page',
		subMenu: null,
	},
	promotionsManagement: {
		id: 'promotionsManagement',
		text: 'Promotions Management',
		path: 'promotions-management',
		icon: 'Campaign',
		subMenu: null,
	},
	helpAndSupportManagement: {
		id: 'helpAndSupportManagement',
		text: 'Help & Support',
		path: 'help-support-management',
		icon: 'Help',
		subMenu: {
			faq: {
				id: 'FAQ',
				text: `FAQ's`,
				path: `FAQ's`,
			},
			contactQueries: {
				id: 'contactQueries',
				text: 'Contact Queries',
				path: 'contact-queries',
			},
		},
	},
	sendNotification: {
		id: 'sendNotification',
		text: 'sendNotification',
		path: 'send-notification',
		hide: true,
		subMenu: null,
	},
	notificationManagement: {
		id: 'notificationManagement',
		text: 'Notifications',
		path: 'notification-management',
		icon: 'Notifications Active',
		subMenu: null,
	},
	profileManagement: {
		id: 'profileManagement',
		text: 'Profile',
		path: 'profile-management',
		icon: 'Account Circle',
		subMenu: null,
	},
	userBookingHistoryDetail: {
		id: 'userBookingHistoryDetail',
		text: 'User Booking History Detail',
		path: 'user-bookingHistory-detail',
		hide: true,
	},
	userDetail: {
		id: 'userDetail',
		text: 'User Detail',
		path: 'user-detail',
		hide: true,
	},
	massageDetail: {
		id: 'massageDetail',
		text: 'Massage Detail',
		path: 'massage-detail',
		hide: true,
	},
	masseuseDetail: {
		id: 'masseuseDetail',
		text: 'Masseuse Detail',
		path: 'masseuse-detail',
		hide: true,
	},
	serviceDetail: {
		id: 'serviceDetail',
		text: 'Service Detail',
		path: 'service-detail',
		hide: true,
	},
	massageCenterDetail: {
		id: 'massageCenterDetail',
		text: 'Massage Center Detail',
		path: 'massage-center-detail',
		hide: true,
	},
	massageCenterHistory: {
		id: 'massageCenterHistory',
		text: 'Massage Center History',
		path: 'massage-center-history',
		hide: true,
	},
	contactQueryDetail: {
		id: 'contactQueryDetail',
		text: 'Contact Query Detail',
		path: 'contactQuery-detail',
		hide: true,
	},
	pastReviewDetail: {
		id: 'pastReviewDetail',
		text: 'Past-review Detail',
		path: 'reviews-management/past-review-detail',
		hide: true,
	},
};

export const demoPagesMenu = {
	login: {
		id: 'login',
		text: 'Logout',
		path: 'login',
		icon: 'Login',
		hide: true,
	},
};
